*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: black;
  color: white;
  margin: 0;
  font-family: "PT Serif", "Noto Sans JP", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.1rem;
}

h1,
h2,
h3,
h4,
a,
span {
  mix-blend-mode: difference;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
